<template>
  <div style="height: 500px; padding-top: 100px">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <rate-test />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RateTest from '@/components/Widgets/Rate/RateTest'
export default {
  components: {
    RateTest
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
