<template>
  <div>
    <highcharts :options="chartOptions" ref="chart"></highcharts>
    <button @click="redrawChart">Redraw</button>
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue'
export default {
  components: {
    highcharts: Chart
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'area'
        },
        series: [
          {
            data: [3, 1, 5, 6, 9, 3, 5, 1]
          }
        ]
      }
    }
  },
  methods: {
    redrawChart () {
      this.$refs.chart.chart.series[0].remove(false)
      this.$refs.chart.chart.addSeries(
        {
          data: [3, 1, 5, 6, 9, 3, 5, 1]
        }
      )
    }
  }
}
</script>
